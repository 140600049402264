import PropTypes from 'prop-types';

import { VideoPlayer } from 'bv-components';
import { useFetch } from 'bv-hooks';
import { isIos } from 'bv-helpers/device';

import fetchStream from 'VirtualSports/api/fetch_stream';

const Player = ({
  sportId,
  eventId,
}) => {
  const [url, fetching] = useFetch(() => (
    fetchStream(sportId, eventId)
  ), [sportId, eventId]);

  if (fetching || !url) return null;

  return (
    <VideoPlayer
      url={url}
      opts={{
        width: '100%',
        height: '280',
        controls: true,
        autoPlay: true,
        muted: isIos(),
        playsInline: isIos(),
      }}
    />
  );
};

Player.propTypes = {
  sportId: PropTypes.number.isRequired,
  eventId: PropTypes.number.isRequired,
};

export default Player;
