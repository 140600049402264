import fetchTree from 'VirtualSports/api/fetch_tree';

export default (handler) => {
  let timeout;

  const scheduledFetch = () => {
    if (timeout) window.clearTimeout(timeout);

    fetchTree()
      .then(handler)
      // Schedule next fetch, polling
      .then(() => { timeout = window.setTimeout(scheduledFetch, 480000); });
  };

  return scheduledFetch;
};
