import { Route } from 'react-router-dom';
import { ContentLayout, SeoTitle } from 'bv-components';
import { t } from 'bv-i18n';
import VirtualsContainer from './virtuals_container';

const VirtualsLayout = () => (
  <ContentLayout>
    <SeoTitle defaultHeading={t('javascript.virtual')} />
    <Route path={['/virtuals/:slug/events/:eventId', '/virtuals/:slug?']}>
      <VirtualsContainer />
    </Route>
  </ContentLayout>
);

export default VirtualsLayout;
