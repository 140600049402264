import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { humanTimeDistance } from 'bv-helpers/datetime';
import { t } from 'bv-i18n';
import { formatNumber } from 'VirtualSports/helpers';

const Timer = ({ events }) => {
  const [time, setTime] = useState(null);

  useEffect(() => {
    const remainingEvents = events.filter((event) => (
      new Date().getTime() < new Date(event.startTime).getTime()),
    );

    const interval = setInterval(() => {
      if (!remainingEvents.length) {
        clearInterval(interval);
      }
      const { minutes, seconds, distance } = humanTimeDistance(remainingEvents[0].startTime);

      if (distance <= 0) {
        setTime(t('javascript.live_now'));
        remainingEvents.shift();
      } else {
        setTime(`${formatNumber(minutes)}:${formatNumber(seconds)}`);
      }
    }, 1000);

    return (() => clearInterval(interval));
  }, [events]);

  return <span className="timer">{time}</span>;
};

Timer.propTypes = {
  events: PropTypes.arrayOf(Object).isRequired,
};

export default Timer;
