import Competitions from './components/competitions';
import Event from './components/event';

const VirtualsView = () => (
  <div className="virtuals-wrapper">
    <Competitions />
    <Event />
  </div>
);

export default VirtualsView;
