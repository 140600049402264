import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Carousel, Link } from 'bv-components';
import { formatNumber } from 'VirtualSports/helpers';

const getDate = (date) => {
  const d = new Date(date);
  const hours = formatNumber(d.getHours());
  const minutes = formatNumber(d.getMinutes());

  return `${hours}:${minutes}`;
};

const Events = ({
  events,
  slug,
  eventId,
}) => (
  <Carousel
    scrollStep={168}
    className="coupon-scroller coupon-scroller-virtuals"
    sliderTagName="ul"
    embedded
  >
    {events.map((event) => (
      <li className="scrolled-item">
        <Link
          to={`/virtuals/${slug}/events/${event.id}`}
          className={
              classnames(
                'scrolled-item__event-button',
                'is-large',
                'bvs-button-chip',
                { active: event.id === eventId },
              )
            }
        >
          <span>{getDate(event.startTime)}</span>
          <span>{event.description.replace(/\s\d\d:\d\d/, '')}</span>
        </Link>
      </li>
    ))}
  </Carousel>
);

Events.propTypes = {
  events: PropTypes.arrayOf(Object).isRequired,
  slug: PropTypes.string.isRequired,
  eventId: PropTypes.number.isRequired,
};

export default Events;
