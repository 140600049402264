import { useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useEventBus } from 'bv-hooks';

import Context from 'VirtualSports/context';
import EventView from './event_view';

const EventContainer = () => {
  const { selectedCompetition, selectedEvent, fetchTree } = useContext(Context);
  const { slug, eventId } = useParams();

  const history = useHistory();

  useEventBus(
    `/essentialscoreboard/${selectedCompetition.sportId}/${selectedEvent.id}`,
    (err, { body: { clockStatus } }) => {
      if (clockStatus !== 'END_OF_EVENT') return;

      // Handle redirections when needed
      // If there is an event id in the URL we will remove it from the URL
      // So we go back to the first event of the competition
      if (eventId) {
        history.replace(['/virtuals', slug].filter(Boolean).join('/'));
      }

      // Trigger a new fetch
      fetchTree();
    },
    [slug, eventId],
  );

  return (
    <EventView
      competition={selectedCompetition}
      event={selectedEvent}
    />
  );
};

export default EventContainer;
