import PropTypes from 'prop-types';

import Player from 'VirtualSports/components/player';
import Events from 'VirtualSports/components/events';
import Markets from 'VirtualSports/components/markets';

const EventView = ({
  competition,
  event,
}) => (
  <>
    <Player sportId={competition.sportId} eventId={event.id} />
    <Events events={competition.events} slug={competition.slug} eventId={event.id} />
    <h2 className="bvs-header">{event.description}</h2>
    {/* Using key so new event reset the internal state of Markets */}
    <Markets
      event={event}
      sportId={competition.sportEventPathId}
      key={event.id}
    />
  </>
);

EventView.propTypes = {
  competition: PropTypes.instanceOf(Object).isRequired,
  event: PropTypes.instanceOf(Object).isRequired,
};

export default EventView;
