import { getJSON } from 'bv-fetch';
import { property } from 'underscore';

export default (sportId, eventId) => (
  // Get provider first
  getJSON(`/bv_media_streaming/events/${eventId}`, {
    sport_id: sportId,
    current_account_only: true,
  })
    .then((data) => (
      data && data.provider_id
        ? getJSON(`/bv_media_streaming/events/${eventId}/url`, {
          sport_id: sportId,
          provider_id: data.provider_id,
        }) : null
    ))
    .catch(() => null)
    .then(property('stream_url'))
);
