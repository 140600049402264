import {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { Spinner } from 'bv-components';

import VirtualsView from './virtuals_view';
import scheduledFetch from './services/scheduled_fetch';
import Context from './context';

const findByOrFirst = (by, array, value) => (
  value ? array.find((item) => item[by] === value) : array[0]
);

const VirtualsContainer = () => {
  const { slug, eventId } = useParams();

  const [{ fetching, tree }, setState] = useState({
    fetching: true,
    tree: [],
  });

  const fetchTree = useCallback(scheduledFetch((response) => {
    setState({
      fetching: false,
      tree: response,
    });
  }), [setState]);

  // Get selectedCompetition from URL param or first
  const selectedCompetition = findByOrFirst('slug', tree, slug);
  // Get selectedEvent from URL param or first
  const { events } = selectedCompetition || { events: [] };
  const selectedEvent = findByOrFirst('id', events, eventId && parseInt(eventId, 10));

  const contextValue = useMemo(
    () => ({
      tree,
      selectedCompetition,
      selectedEvent,
      fetchTree,
    }),
    [tree, selectedCompetition, selectedEvent],
  );

  useEffect(() => { fetchTree(); }, [fetchTree]);

  if (fetching) return <Spinner />;
  if (!tree.length) return null;

  // If slug in URL but not in the list of competitions, redirect to /virtuals
  if (!selectedCompetition) return <Redirect to="/virtuals" />;
  // If eventId in URL but not in the list of events, redirect to /virtuals/slug
  if (!selectedEvent) return <Redirect to={`/virtuals/${slug}`} />;

  return (
    <Context.Provider value={contextValue}>
      <VirtualsView />
    </Context.Provider>
  );
};

export default VirtualsContainer;
