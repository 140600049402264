import { useState } from 'react';
import PropTypes from 'prop-types';
import { useEventBus } from 'bv-hooks';
import Markets from 'sportsbook-markets-v2';

const MarketsContainer = ({
  sportId,
  event,
}) => {
  const {
    startTime,
    id: eventId,
  } = event;

  const [disabled, setDisabled] = useState(
    new Date(startTime) < new Date(),
  );

  useEventBus(`/event/${eventId}/marketsummary`, (err, { body: { u: updated } }) => {
    if (updated[0].st !== 1) setDisabled(true);
  }, [eventId]);

  return (
    <div className={disabled ? 'disabled' : ''}>
      <Markets sportId={sportId} eventId={eventId} open={2} />
    </div>
  );
};

MarketsContainer.propTypes = {
  sportId: PropTypes.number.isRequired,
  event: PropTypes.instanceOf(Object).isRequired,
};

export default MarketsContainer;
