import { useContext } from 'react';
import classnames from 'classnames';

import { Carousel, Link, Icon } from 'bv-components';

import Context from 'VirtualSports/context';
import Timer from './timer';

const Competitions = () => {
  const { tree, selectedCompetition } = useContext(Context);

  return (
    <Carousel
      scrollStep={88}
      className="coupon-scroller coupon-scroller-virtuals"
      sliderTagName="ul"
      embedded
    >
      {tree.map((competition) => (
        <li className="scrolled-item">
          <Link
            className={classnames('scrolled-item__sport-button', {
              disabled: competition.slug !== selectedCompetition.slug,
            })}
            to={`/virtuals/${competition.slug}`}
          >
            <Icon id={`sport-${competition.sportId}`} type="virtual-scroller-item-icon" />
            <span className="virtual-name">{competition.description.replace('Virtual ', '')}</span>
            <Timer events={competition.events} />
          </Link>
        </li>
      ))}
    </Carousel>
  );
};

export default Competitions;
